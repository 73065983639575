import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import setLanguageHeader from "./utils/setLanguageHeader";
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/fr';

// import translationEN from '../../server/locales/en/translation.json';
// import translationFR from '../../server/locales/fr/translation.json';
//
// // the translations
// const resources = {
//     en: {
//         translation: translationEN
//     },
//     fr: {
//         translation: translationFR
//     }
// };

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);


const detectorOptions = {
    // order and from where user language should be detected
    order: ['localStorage','cookie', 'navigator', 'htmlTag','querystring', 'path', 'subdomain'],

    // keys or params to lookup language from
    //lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    //lookupFromPathIndex: 0,
    //lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    //cookieMinutes: 10,
    //cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    //htmlTag: document.documentElement,

    // only detect languages that are in the whitelist
    checkWhitelist: true
};

i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
    setLanguageHeader(lng);
});

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng: "fr",
        //resources,
        fallbackLng: "fr", // use en if detected lng is not available
        keySeparator: ".", // we do not use keys in form messages.welcome
        debug: isLocalhost,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            //useSuspense: false
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        detection:detectorOptions,
        load: 'languageOnly'
    },(err,t)=>{
        moment.locale(i18n.language);
        console.log("loaded....", t);
    });

export default i18n;
