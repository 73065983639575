import React, { Component } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";

import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
// import { sendContactMeEmail } from "../../actions/emailActions"
// import image from "../../../public/tets logo violet sans fond.png"
class ContactMeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            email: "",
            message: "",
            company: "",
            loading: false
        };
    }

    async sendEmail() {
        const { email, message, company } = this.state
        try {
            this.setState({ loading: true })
            let response = await axios.get('/api/email/sendContactMeEmail', { params: { email, message, company } });
            this.setState({
                loading: false,
                show: false
            })
            console.log(response)
        } catch (err) {
            console.log(err)
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        const { show, loading } = this.state;
        const { t } = this.props;
        return (
            <>
                <Button className="rounded-pill position-absolute hover-white contact-me-btn no-hover-bg-white" variant="outline-alternative-purple" onClick={() => this.setState({ show: true })}>
                    {t("contactMe", "Contact Me")}
                </Button>
                <Modal show={show} onHide={() => this.setState({ show: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("contactMeModalTitle", "Let's talk !")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Trans i18nKey={"contactMeModalDescription"}>
                            If you liked my website and wanna chat, leave me a message right below ☕
                        </Trans>

                        <Form>

                            <FloatingLabel className="mt-4 mb-3" controlId="email" label={t("emailTitle", "What is your email ?")} >
                                <Form.Control required placeholder={t("emailTitle", "What is your email ?")} onChange={this.onChange} />
                            </FloatingLabel>
                            <FloatingLabel controlId="message" label={t("messageTitle", "Your message...")} className="mb-3">
                                <Form.Control
                                    required
                                    as="textarea"
                                    placeholder="What is your message ?"
                                    style={{ height: '100px' }}
                                    onChange={this.onChange}
                                />
                            </FloatingLabel>
                            <FloatingLabel className="mb-3" controlId="company" label={t("companyTitle", "Company (optionnal)")} >
                                <Form.Control placeholder={t("companyTitle", "Company ? (optionnal)")} onChange={this.onChange} />
                            </FloatingLabel>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-alternative-purple" disabled={loading} className="hover-white" onClick={() => this.sendEmail()}>
                            {loading === true ?
                                <span>
                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                                    {t("sendMessage", "Send message")}
                                </span>
                                :
                                t("sendMessage", "Send message")
                            }
                        </Button>

                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

ContactMeModal.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(ContactMeModal);