import React from 'react';
import PropTypes from "prop-types";

const LoadingSpinner = ({ children, className = "", containerClassName=""}) => {
  return (
    <div className={"d-flex justify-content-center "+containerClassName}>
      <div className={"spinner-grow text-alternative-purple " + className} style={{width: "3rem", height: "3rem"}} role="status">
      </div>
      <span className="flex-center ps-2">{children}</span>
    </div>
  );
};

LoadingSpinner.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  containerClassName: PropTypes.any,
}
export default LoadingSpinner;