import React, { useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function GalleryHome() {
    const [magic, setMagic] = useState(1)

    const handleClick = (e) => {
        e.preventDefault()
        setMagic(magic => {
            return magic+1
        })
    }

    const Composant = () => {
        return (
            <div>
                HEYYYY
            </div>            
        )
    }    

    return (
        <>
            <Composant/>
            <span>
                coucou {magic}
            </span>
            <button className="btn btn-primary" onClick={handleClick}>
                Ajouter de la magie à coucou
            </button>
        </>
    )
}

GalleryHome.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(GalleryHome);