import React, { Component } from "react";
import { Link} from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';

class urlNotFound extends Component {
    
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <h4>
                            <Trans i18nKey="urlNotFoundError">
                                <b>ERROR 404 Page not found</b>
                            </Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="urlNotFoundMessage">
                                Page not found, please get back to the <Link to="/">homepage</Link> 🤷
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(urlNotFound);
