import React, { Component } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import cvPicture from "../../images/logo/cvPicture.png"
import CV2023Anonyme from "../../images/pdf/CV_2023_anonyme.pdf"

// import { sendContactMeEmail } from "../../actions/emailActions"
// import image from "../../../public/tets logo violet sans fond.png"
class ResumeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    setResumeModalShow() {
        this.setState({ show: false });
    }

    render() {
        const { show } = this.state;
        // const { t } = this.props;
        return (
            <>
                <div className="col-12 text-center">
                    <img className="zoom-hover p-2 bg-primary cursor-pointer rounded" onClick={() => this.setState({ show: true })} src={cvPicture} style={{ height: 450, width: "auto" }} />
                </div>
                <Modal
                    show={show}
                    onHide={() => this.setResumeModalShow(false)}
                    dialogClassName="modal-75w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12 mx-auto" style={{ height: "80vh" }}>
                            <iframe src={CV2023Anonyme} width="100%" height="100%" />
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

ResumeModal.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(ResumeModal);