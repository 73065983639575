import React, { useState, useRef, useEffect } from "react";
// import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// import image from "../../../public/tets logo violet sans fond.png"





function TestHooks() {
  const [count, setCount] = useState({
    a: 1,
  });

  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // L'élément est visible à l'écran, appliquez l'animation ici
          textRef.current.classList.add('fadeInAnimation');
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(textRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault()
    setCount(count => {
      return { ...count, text: "coucou" }
    })
  }

  return (
    <div>
      <p>Vous avez cliqué {count.a} fois ({count?.text})</p>
      <p>{JSON.stringify(count)}</p>
      <button onClick={handleClick}>
        Cliquez ici
      </button>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <p ref={textRef} className="animated-text text-center">
        Votre texte ici
      </p>

    </div>
  );
}

TestHooks.propTypes = {
  t: PropTypes.func.isRequired,
}
export default withTranslation()(TestHooks);