import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";

const AnimatedText = ({children, className = ""}) => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // L'élément est visible à l'écran, appliquez l'animation ici
          textRef.current.classList.add('fadeInAnimation');
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(textRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={textRef} className={"animated-text " + className} >
        {children}
    </div>
  );
};

AnimatedText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
}
export default AnimatedText;