import React, { Component, Suspense } from "react";
// import NavigationBar from "./components/layout/navbar";
import Landing from "./components/homePage/landing";
import Test from "./components/homePage/test";
import Login from "./components/auth/login";
import GalleryHome from "./components/gallery/galleryHome";
import ErrorUrlNotFound from "./components/error/urlNotFound"
import ReactGA from "react-ga4";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoadingSpinner from "./components/utils/loadingSpinner";


// Check for token to keep user logged in
// if (localStorage.jwtToken) {
//     // Set auth token header auth
//     const token = localStorage.jwtToken;
//     setAuthToken(token);
//     // Decode token and get user info and exp
//     const decoded = jwt_decode(token);
//     // Set user and isAuthenticated
//     store.dispatch(setCurrentUser(decoded));
//     // Check for expired token
//     const currentTime = Date.now() / 1000; // to get in milliseconds
//     if (decoded.exp < currentTime) {
//         // Logout user
//         store.dispatch(logoutUser());
//         // Redirect to login
//         window.location.href = "./login";
//     }
// }

// google analytics
const TRACKING_ID = "G-9WRCGQHFQ4"
ReactGA.initialize(TRACKING_ID)

class App extends Component {
    render() {
        return (
            <Suspense fallback={<LoadingSpinner containerClassName="mt-5">Loading...</LoadingSpinner>}>
                <Router>
                    <div className="main-container" style={{ overflowX: "hidden" }}>
                        {/* <NavigationBar /> */}
                        <div className="container-fluid">
                            <div className="row">
                                {/* put sidenav here */}
                                <div className="col p-0" id="globalContainer">
                                    <Switch>
                                        <Route exact path="/" component={Landing} />
                                        <Route exact path="/test" component={Test} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/gallery" component={GalleryHome} />
                                        {/* <Route exact path="/login" component={Login} /> */}
                                        <Route component={ErrorUrlNotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
            </Suspense>
        );
    }
}
export default App;