import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

// Login - get user token
export const loginUser = async userData => {
    console.log("1")
    try{
        let res = await axios.post("/api/users/login", userData)
        if(res){
             // Save to localStorage
             const { token } = res.data;
             localStorage.setItem("jwtToken", token);
             // Set token to Auth header
             setAuthToken(token);
             // Decode token to get user data
             const decoded = jwt_decode(token);
             console.log(decoded)
             // Set current user
             // dispatch(setCurrentUser(decoded));
             return decoded
        }

    }
    catch(err){
        console.log(err.response)
        return err.response
    }
};