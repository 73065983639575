import axios from "axios";
const setLanguageHeader = lang => {
    if (lang) {
        // Apply language to every request
        axios.defaults.headers.common["accept-language"] = lang;
    } else {
        // Delete auth header
        delete axios.defaults.headers.common["accept-language"];
    }
};
export default setLanguageHeader;
