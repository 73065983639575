import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { ReactComponent as MyLogo } from "../../images/logo/logo.svg"
import { ReactComponent as ArrowIcon } from "../../images/utils/pppointedTop.svg"
import panorasoftLogo from "../../images/logo/LogoPanorasoft-WhiteGrey-207x80.png"
import logoAraoo from "../../images/logo/logoAraoo.webp"
import scrapingbotlogo from "../../images/logo/scrapingbotlogo.png"
import primalLogo from "../../images/logo/primal.png"
import solemLogo from "../../images/logo/solem.png"
import attentiveLogo from "../../images/logo/attentive.png"
import indygoLogo from "../../images/logo/indygo.png"
import crossshopperLogo from "../../images/logo/crossshopperLogo.png"
import yieldbookingLogo from "../../images/logo/yieldbookingLogo.png"
// import profilePicture from "../../images/logo/profilePicture250.png"
import CV2023 from "../../images/pdf/CV_2023.pdf"
import bgland from "../../images/utils/DSC_8204-6.jpg"
import PageItem from "./pageItem";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import ButtonsChangeLanguage from "../buttons/ButtonsChangeLanguage";
import SocialMedias from "../utils/socialMedias";
import ContactMeModal from "../utils/contactMeModal";
import ResumeModal from "../utils/resumeModal";
import ReactGA from "react-ga4";
import AnimatedText from "../utils/animatedText";


// import image from "../../../public/tets logo violet sans fond.png"
class Landing extends Component {

    componentDidMount(){
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "landing" });
    }

    render() {
        const { t } = this.props
        let sectionSpace = 150;
        return (
            <div className="h-100 bg-light">
                {/* <PageItem bgClass={""} style={{ backgroundImage: `url("https://www.toptal.com/designers/subtlepatterns/uploads/funky-lines.png")` }}> */}
                <PageItem bgClass={"bg-landing-cover"}
                 style={{ backgroundImage: `url(${bgland})` }}
                >
                    <div className="row pt-4">
                        <ButtonsChangeLanguage />
                        <div className="col-12 p-0 text-center flex-center">
                            <MyLogo height="60px" width="60px" />
                            <ContactMeModal />
                        </div>
                    </div>
                    <div className="row text-white" style={{ marginTop: "10rem" }}>
                        <h1 className="text-center">
                            {t("mainPageTitle", "Full stack Developer")}
                        </h1>
                        <h4 className="text-center fw-lighter">
                            {t("mainPageDescription", "I design and code beautifully simple things, and I love what I do.")}
                        </h4>
                        {/* <div className="col-12 text-center mt-5">
                            <img src={profilePicture} className="p-0 profile-picture rounded-pill shadow" />
                        </div> */}
                    </div>
                </PageItem>
                <hr className="m-0 opacity-100 text-secondary"/>
                <PageItem bgClass="bg-yellow" fullPage={false}>
                    <div className="col-xl-6 col-10 mx-auto text-center" style={{ paddingTop: "5rem", paddingBottom: sectionSpace + 100 }}>
                        <AnimatedText>

                            <h3 className="mb-3">
                                {t("descriptionTitle", "Hi, I’m Florian. Nice to meet you.")}
                            </h3>
                        </AnimatedText>
                        <AnimatedText>
                            <h6 style={{ lineHeight: "1.8" }}>
                                <Trans i18nKey={"descriptionText"}>
                                    J&apos;ai commencé a travailler comme Developpeur il y a 3 ans, dans un premier temps pour un comparateur de prix collaboratif puis dans un outil de veille tarifaire proposé à de grands campings européens. Avant cela j&apos;ai fait des stages en entreprise durant lesquels j&apos;ai participé à la création de sites pour des profesionnels. Je suis curieux de nature, autonome et j&apos;aime aller au bout des choses.
                                </Trans>
                            </h6>
                        </AnimatedText>
                    </div>
                </PageItem>
                <PageItem
                // bgClass="bg-alternative-purple"
                >

                    <div className="d-flex justify-content-center mb-5" style={{ marginTop: "-" + sectionSpace + "px", minHeight: "300px" }}>
                        <div className="bg-white col-xl-7 col-lg-9 col-md-11 col-12 rounded-10 shadow-sm row text-center border border-crossed-logo">
                            <div className="col-lg-4 col-12 border-right-lg border-crossed-logo">
                                <h4 className="pt-5 fw-bold">
                                    {t("dataBaseDeveloper", "Data bases")}
                                </h4>
                                <hr style={{ margin: "15px 20%" }} />
                                <p className="pt-2">
                                    <Trans i18nKey={"dataBaseDescription"}>
                                        J&apos;ai eu l&apos;occasion de travailler avec differents systeme de base de données.
                                    </Trans>
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("usedTechnologies", "Used technologies")}
                                </h5>
                                <p>
                                MongoDB, SQL
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("dataBasesTools", "Database tools")}
                                </h5>
                                <ul className="no-bullet">
                                    <li>SQL Server</li>
                                    <li>DataGrip</li>
                                    <li>Robo3T / Studio3T</li>
                                    <li>MongoDB Atlas</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-12 border-right-lg border-crossed-logo">
                                <h4 className="pt-5 fw-bold">
                                    {t("fullStackDeveloper", "Full stack Developer")}
                                </h4>
                                <hr style={{ margin: "15px 20%" }} />
                                <p className="pt-2">
                                    <Trans i18nKey={"fullStackDeveloperDescription"}>
                                        During my journey through web development I&apos;ve been working with some nice technologies <br /> J&apos;aime coder des fonctionnalités &quot;from scratch&quot; et donner vie a des pages fonctionnelles et efficaces.
                                    </Trans>
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("fullStackDeveloperProgrammingLanguage", "Programming languages")}
                                </h5>
                                <p>
                                    JavaScript, TypeScript, EJS C#, Java, HTML, CSS
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("fullStackDeveloperFrameWorksAndLibrairies", "Frameworks and librairies")}
                                </h5>
                                <p>
                                    ReactJs, NodeJs, AngularJs, VueJs, .NET, Axios, Pupeteer, Moment, Express
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("fullStackDeveloperDevTools", "Dev tools")}
                                </h5>
                                <ul className="no-bullet">
                                    <li>Visual Studio Code</li>
                                    <li>Docker</li>
                                    <li>Windows / Linux</li>
                                    <li>Git</li>
                                    <li>BitBucket</li>
                                    <li>SourceTree / GitKraken</li>
                                    <li>Bootstrap</li>
                                    <li>Trello</li>
                                    <li>Stack Overflow ({t("indeed", "indeed")})</li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-12">
                                <h4 className="pt-5 fw-bold">
                                    {t("furtherInformation", "Further information")}
                                </h4>
                                <hr style={{ margin: "15px 20%" }} />
                                <p className="pt-2">
                                    <Trans i18nKey={"furtherInformationDescription"}>
                                        During my journey through web development I&apos;ve been working with some nice technologies
                                    </Trans>
                                </p>
                                <h5 className="pt-4 text-alternative-purple">
                                    {t("thingsILike", "Things I like doing")}
                                </h5>
                                <ul className="no-bullet">
                                    <li>{t("gaming", "Gaming 🎮")}</li>
                                    <li>{t("learning", "Learning 📚")}</li>
                                    <li>{t("travel", "Travel ✈️")}</li>
                                    <li>{t("music", "Music 🎵")}</li>
                                    <li>{t("photography", "Photography 📸")}</li>
                                    <li>{t("cinnema", "Cinema 🍿")}</li>
                                    <li>{t("iceSkating", "Ice skating ⛸️")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <AnimatedText>
                            <h3 className="text-center mt-2">
                                {t("schoolCareer", "School career")}
                            </h3>
                            <div className="col-12 p-0 mb-4">
                                <hr style={{ margin: "10px 40%" }} />
                            </div>
                        </AnimatedText>
                        <AnimatedText className="animated-text col-12 col-xl-6 col-lg-8 col-sm-10 mx-auto bg-yellow fadeInAnimation rounded-school-career shadow-sm">
                            <div className="col-12 mt-3 text-center">
                                <h5>
                                    {t("bachelorsDegree", "Licence professionnelle APIDAE")}
                                </h5>
                                <p>
                                    {t("bachelorsDegreeDescription", "(Assistant de Projet Informatique, Développement d’Application E-business)")}
                                </p>
                                <p>{t("bachelorsDegreeDateAndPlace", "2018/2019 - IUT Montpellier/Sète")}</p>
                            </div>
                        </AnimatedText>
                        <div className="text-center">
                            <AnimatedText>
                                <ArrowIcon height="60px" width="60px" />
                            </AnimatedText>
                        </div>
                        <AnimatedText className="animated-text col-12 col-xl-6 col-lg-8 col-sm-10 mx-auto bg-alternative-purple fadeInAnimation rounded-school-career shadow-sm text-white">
                            <div className="col-12 mt-3 text-center">
                                <h5>
                                    {t("twoYearTechnicalDegree", "BTS SIO")}
                                </h5>
                                <p className="mb-0">
                                    {t("twoYearTechnicalDegreeDescription", "(Services informatiques aux organisations)")}
                                </p>
                                <p className="small fst-italic">
                                    {t("twoYearTechnicalDegreeOption", "Option: Solutions Logicielles et Applications Métier")}
                                </p>
                                <p>{t("twoYearTechnicalDegreeDateAndPlace", "2016/2018 - Lycée Mermoz Montpellier")}</p>
                            </div>
                        </AnimatedText>
                        <div className="text-center">
                            <AnimatedText>
                                <ArrowIcon height="60px" width="60px" />
                            </AnimatedText>
                        </div>
                        <AnimatedText className="animated-text col-12 col-xl-6 col-lg-8 col-sm-10 mx-auto bg-crossed-logo fadeInAnimation rounded-school-career shadow-sm">
                            <div className="col-12 mt-3 mb-4 text-center">
                                <h5>
                                    {t("highSchoolDegree", "Bac STI2D")}
                                </h5>
                                <p className="mb-0">
                                    {t("highSchoolDegreeDescription", "(Sciences et technologies de l'industrie et du développement durable)")}
                                </p>
                                <p className="small fst-italic">
                                    {t("highSchoolDegreeOption", "Option: Systèmes d’information et numérique")}
                                </p>
                                <p>{t("highSchoolDegreeDateAndPlace", "2014/2016 - Lycée Mermoz Montpellier")}</p>
                            </div>
                        </AnimatedText>
                    </div>

                    <div className="row">
                        <AnimatedText>
                            <h3 className="text-center mt-5">
                                {t("professionnalCareer", "Professionnal career")}
                            </h3>
                            <div className="col-12 p-0 mb-4">
                                <hr style={{ margin: "10px 40%" }} />
                            </div>
                        </AnimatedText>

                        {/* Primal */}
                        <div className="col-12 col-xl-6 col-lg-8 col-sm-10 mb-4 mx-auto py-5 row text-center resume-frame-purple border-10 border-solid">
                            <AnimatedText>
                                <div className="col-12 p-0 mb-4">
                                    <img style={{ height: 30, width: "auto" }} src={primalLogo} />
                                </div>
                                <p className="mb-0">
                                    {t("fullStackDeveloper", "Full Stack developer")} | {t("november2023","November 2023")} - {t("today", "Today")}
                                </p>
                                <p className="small fst-italic mb-4">
                                    Javascript, NodeJS, EJS, ReactJS, MongoDB, SCSS
                                </p>
                            </AnimatedText>
                            <AnimatedText>

                                <Trans i18nKey="primalDescription">
                                    <p className="mb-2">
                                        I am currently working at Primal, a company that develops and industrializes connected solutions (IOT) in three major sectors of activity.
                                    </p>
                                    <p className="mb-2">
                                        SOLEM for the environment, ATTENTIVE in the field of health and MYINDYGO for the swimming pool
                                    </p>
                                    <p className="mb-2">    
                                        I work in the R&D department and mainly for the mysolem platform.
                                    </p>
                                    <p className="mb-2">
                                        My missions are, among other things, to create new features for the platform, improve the existing ones, correct bugs, etc.
                                    </p>
                                </Trans>
                                <div className="align-items-center row mt-4">
                                    <div className="col-xl-4 col-12">
                                        <img style={{ height: 60, width: "auto" }} src={solemLogo} />
                                    </div>
                                    <div className="col-12 col-xl-4 mb-4 mb-xl-0 mt-4 mt-xl-0">
                                        <img style={{ height: "auto", width: 200 }} src={attentiveLogo} />
                                    </div>
                                    <div className="col-xl-4 col-12">
                                        <img style={{ height: 40, width: "auto" }} src={indygoLogo} />
                                    </div>
                                </div>
                            </AnimatedText>
                        </div>
                        
                        <div className="text-center" style={{paddingRight: 37}}>
                            <AnimatedText>
                                <ArrowIcon height="60px" width="60px" />
                            </AnimatedText>
                        </div>

                        {/* PanoraSoft */}
                        <div className="col-12 col-xl-6 col-lg-8 col-sm-10 mb-4 mt-4 mx-auto py-5 row text-center resume-frame-purple border-10 border-dashed">
                            {/* <h5>
                                {t("panoraSoft", "PanoraSoft")}
                            </h5> */}
                            <AnimatedText>
                                <div className="col-12 p-0 mb-4">
                                    <img style={{ height: 60, width: 155 }} src={panorasoftLogo} />
                                </div>
                                <p className="mb-0">
                                    {t("fullStackDeveloper", "Full Stack developer")} | 2019 - {t("august2022", "August 2022")}
                                </p>
                                <p className="small fst-italic mb-4">
                                    ReactJS, NodeJS, MongoDB, Angular, ASP.NET
                                </p>
                            </AnimatedText>
                            <AnimatedText>

                                <Trans i18nKey="panoraSoftDescription">
                                    <p className="mb-2">
                                        For the last 3 years at PanoraSoft Ive been participating to a lot of aspects of the services the company offered.
                                    </p>
                                    <p className="mb-2">
                                        First with AngularJS and ASP.NET for a C2C price comparator named CrossShopper that lasted 3 years.
                                    </p>
                                    <p className="mb-2">
                                        After CrossShopper the company created several services inncluding YieldBooking in which I had the chance to be the first team developper to work there
                                    </p>
                                    <p className="mb-2">
                                        YieldBooking is a B2B yield tool intended for campsites based on the MERN stack.
                                    </p>
                                </Trans>
                                <div className="align-items-center row mt-4">
                                    <div className="col-4">
                                        <img src={crossshopperLogo} />
                                    </div>
                                    <div className="col-4">
                                        <img style={{ height: 49, width: 60 }} src={yieldbookingLogo} />
                                    </div>
                                    <div className="col-4">
                                        <img style={{ height: 49, width: 60 }} src={scrapingbotlogo} />
                                    </div>
                                </div>
                            </AnimatedText>
                        </div>

                        <div className="text-center" style={{paddingRight: 37}}>
                            <AnimatedText>
                                <ArrowIcon height="60px" width="60px" />
                            </AnimatedText>
                        </div>

                        {/* araoo */}
                        <div className="col-12 col-xl-6 col-lg-8 col-sm-10 mt-4 mx-auto py-5 row text-center resume-frame-purple border-10 border-dashed">
                            {/* <h5>
                                {t("araoo", "Araoo")}
                            </h5> */}
                            <AnimatedText>
                                <div className="col-12 p-0 mb-4">
                                    <img style={{ height: 60, width: 155 }} src={logoAraoo} />
                                </div>
                                <p className="mb-0">
                                    {t("aspNetDevInIntership", "ASP.NET Developer in intership")} | 8 {t("week", { defaultValue: "week", count: 8 })} - 2018
                                </p>
                                <p className="small fst-italic mb-4">
                                    ASP.NET, SQLs
                                </p>
                            </AnimatedText>
                            <AnimatedText>
                                <Trans i18nKey="araooDescription">
                                    I did a 2 month internship at Araoo. I mainly worked as a front-end developer for one of the company&apos;s clients that own a social media for cosmetics influencers
                                </Trans>
                            </AnimatedText>
                        </div>
                    </div>

                    <div className="row text-center">

                        <h3 className="text-center mt-5">
                            {t("resume", "Resume")}
                        </h3>
                        <div className="col-12 p-0 mb-4">
                            <hr style={{ margin: "10px 40%" }} />
                        </div>

                        <ResumeModal />

                        <p className="mt-3">
                            <Trans i18nKey="detailedResumeDescription">
                                You can find my resume with all information <a className="link link--eirene text-decoration-none hover-primary" target="_blank" rel="noreferrer" href={CV2023}>here</a>.
                            </Trans>
                        </p>
                    </div>

                </PageItem>
                <div className="footer bg-primary mt-5">
                    <div className="pt-5 d-flex justify-content-center">
                        <MyLogo height="60px" width="60px" />
                    </div>
                    <SocialMedias />
                </div>
            </div >
        );
    }
}

Landing.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(Landing);