import React, { Component } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {  withTranslation } from "react-i18next";
// import image from "../../../public/tets logo violet sans fond.png"
class SocialMedias extends Component {
    render() {
        // const { t } = this.props
        let btnHeight = 50;
        return (
            <div className="col-6 d-flex justify-content-center mx-auto pt-5 text-center">
                <a href="https://www.linkedin.com/in/florian-dominguez-98669821a/" target="_blank" rel="noreferrer" className="text-decoration-none me-3">
                    <button className="btn footer-social-media-btn rounded-circle flex-center" style={{height: btnHeight, width: btnHeight}}>
                        <i className="fab fa-linkedin-in fa-1-2x"/>
                    </button>
                </a>
                <a href="mailto:floriandominguez.contact@gmail.com" className="text-decoration-none">
                    <button className="btn footer-social-media-btn rounded-circle flex-center" style={{height: btnHeight, width: btnHeight}}>
                        <i className="fas fa-envelope fa-1-2x"/>
                    </button>
                </a>
            </div >
        );
    }
}

SocialMedias.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(SocialMedias);