import React, { Component } from "react";
// import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TestHooks from "./testHooks";
import ReactGA from "react-ga4";

// import image from "../../../public/tets logo violet sans fond.png"
class Landing extends Component {
    componentDidMount(){
        ReactGA.send({ hitType: "pageview", page: "/test", title: "test" });
    }


    render() {

        return (
            <div className="h-100">
                    <p className="trorigolo-text">
                        hehehe c&apos;est un text trop marrant
                    </p>
                <TestHooks/>
                <div className="row p-5">
                    <div className="col-12">
                        <div style={{width:200, height:50}} className="align-items-center d-flex justify-content-center p-2 rounded-pill test-btn">
                            <span>
                                Vois comme je grandis !
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

Landing.propTypes = {
    t: PropTypes.func.isRequired,
}
export default withTranslation()(Landing);