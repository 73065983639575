import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as MyLogo } from "../../images/logo/logo.svg"
import ButtonsChangeLanguage from "../buttons/ButtonsChangeLanguage";
import { Link,useHistory } from "react-router-dom";
import { loginUser } from "../../actions/authActions";

function Login() {
    let { t } = useTranslation()
    const history = useHistory();
    const [signInData, setSignInData] = useState({
        email: "",
        password: "",
        rememberMe: false
    })
    
    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        setSignInData({...signInData,[e.target.id]:e.target.value})
    }

    const handleChangeCheckBox = (e) => {
        setSignInData({...signInData,[e.target.id]:e.target.checked})
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        console.log("LOG IN -> ",signInData);
        let res = await loginUser(signInData)
        if(res.status >= 400 && res.status <500){
            if(res?.data){
                setErrors(res.data)
            }
        }else{
            setErrors({})
            history.push("/")
        }
        console.log(res)
    }

    return (
        <div className="h-100">
            <ButtonsChangeLanguage className="ms-3" />

            <Form className="text-center form-signin" style={{ marginTop: "5rem" }} onSubmit={handleSubmit}>
                <MyLogo className="mb-4" height="80px" width="80px" />

                <h5>{t("login", "Login")}</h5>
                <Form.Group className="mb-2" controlId="email">
                    <Form.Control type="email" placeholder={t("emailAddress", "Email address")} onChange={handleChange} required/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Control type="password" placeholder={t("password", "Password")} value={signInData.password} onChange={handleChange} isInvalid={errors?.password} />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <div className="form-check mb-4">
                    <input className="form-check-input float-none me-3" type="checkbox" id="rememberMe" checked={signInData.rememberMe} onChange={handleChangeCheckBox} />
                    <label className="form-check-label" htmlFor="rememberMe">
                        {t("rememberMe", "Remember me")}
                    </label>
                </div>

                <Button variant={"alternative-purple w-100 "+(!errors.passwordOrEmailInvalid?"mb-3":"mb-1")} className="text-white" type="submit">
                    {t("signIn", "Sign In")}
                </Button>
                {errors?.passwordOrEmailInvalid && 
                    <div className="text-danger mb-2">{errors.passwordOrEmailInvalid}</div>
                }

                <Link to={"/"} className="float-end">
                    <Button variant="grey" size="sm">
                        {t("back", "Back")}
                    </Button>
                </Link>

            </Form>
        </div>
    )
}

export default Login;