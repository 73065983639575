import React, { Component } from "react";
import { withTranslation} from 'react-i18next';


import frenchFlag from "../../images/flags/France.png"
import ukFlag from "../../images/flags/United-Kingdom.png"
// import esFlag from "../../images/flags/Spain.png"
// import { connect } from "react-redux";
// import {editUserField} from "../../actions/userActions"
import PropTypes from "prop-types";


class ButtonsChangeLanguage extends Component {
    render() {
        const { i18n, className = "" } = this.props;

        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);

        };

        return (
            <div className={className +" pl-3 pt-3"}>
                <img style={{height:20,cursor:"pointer"}} className="me-2 shadow-sm" src={frenchFlag} onClick={() => changeLanguage('fr')} alt="french flag"/>
                <img style={{height:20,cursor:"pointer"}} className="me-2 shadow-sm" src={ukFlag} onClick={() => changeLanguage('en')} alt="united kingdom flag"/>
            </div>
        );
    }
}

ButtonsChangeLanguage.propTypes = {
    i18n: PropTypes.object.isRequired,
    className: PropTypes.string,
};


export default withTranslation()(ButtonsChangeLanguage);
