import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { ReactComponent as MyLogo } from "../../images/logo/logo.svg"
// import image from "../../../public/tets logo violet sans fond.png"
class PageItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { bgClass ="", fullPage = true, style = {}} = this.props
        return (
            <div style={{...style, minHeight: (fullPage? "100vh":"") }} className={"container-fluid "+bgClass}>
                {this.props.children}
            </div>
        ); 
    }
}

PageItem.propTypes = {
    children: PropTypes.node,
    bgClass: PropTypes.string,
    style: PropTypes.object,
    fullPage: PropTypes.bool,
}

export default PageItem;